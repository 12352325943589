<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();

  function setClickUrl(url) {
    window.location = url;
  }
  const office_location = "/assets/img/locations.png";
</script>

<footer class="relative bg-blueGray-200 pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
        <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
          Find us on any of these platforms, we respond within 1-2 business days.
        </h5>
        <div class="mt-6 lg:mb-0 mb-6">
          <a
            href="https://www.linkedin.com/company/sabhyatech/"
            target="_blank"
          >
            <button
              class="bg-white text-lightBlue-400 shadow-lg font-normal
              h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"><i class="fab fa-linkedin" /></button
            >
          </a>

          <a href="https://twitter.com/SabhyaTech" target="_blank">
            <button
              class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-twitter" />
            </button>
          </a>
          <a
            href="https://www.facebook.com/Sabhya-Technologies-113241626814929/"
            target="_blank"
          >
            <button
              class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-facebook-square" />
            </button>
          </a>
          <a href="https://github.com/sabhyadb" target="_blank">
            <button
              class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-github" />
            </button>
          </a>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <span class="font-bold">Global Headquaters </span>
        <p>
          250 N Sunny Slope Rd #200,<br />
          Brookfield, WI 53005.
        </p>
        <p>&nbsp;</p>
        <p>
          <a
            target="_new"
            href="https://www.google.com/maps/place/Sabhya+Technologies/@43.0331138,-88.0853964,21z/data=!3m1!5s0x880505d751786089:0x956e365651cbf73d!4m14!1m7!3m6!1s0x8805058186ea19a3:0x863934bfd512977c!2sSabhya+Technologies!8m2!3d43.033079!4d-88.0852717!16s%2Fg%2F11kg02hpws!3m5!1s0x8805058186ea19a3:0x863934bfd512977c!8m2!3d43.033079!4d-88.0852717!16s%2Fg%2F11kg02hpws?entry=ttu"
            >Directions</a
          >
        </p>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <h5 class="font-bold text-center">Global Offices</h5>
        <img src={office_location} alt="office locations" />
      </div>
    </div>
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">
          Copyright © {date}
          <a
            href="https://sabhyatech.com"
            class="text-blueGray-500 hover:text-blueGray-800"
          >
            Sabhya Technologies.
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
