<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Admin Layout
  // import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  // import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  // import Landing from "./views/Landing.svelte";
  // import Profile from "./views/Profile.svelte";
  import Events from "./views/Events.svelte";
  import Partners from "./views/Partners.svelte";
  import Contact from "./views/Contact.svelte";
  import saas from "./views/saas.svelte";
  import daas from "./views/daas.svelte";
  import consult from "./views/consult.svelte";
  import si from "./views/si.svelte";
  import security from "./views/security.svelte";
  import build from "./views/build.svelte";
  import builda from "./views/builda.svelte";

  export let url = "";
</script>

<Router url="{url}">
  <!-- admin layout -->
  <!-- <Route path="admin/*admin" component="{Admin}" /> -->
  <!-- auth layout -->
  <!-- <Route path="auth/*auth" component="{Auth}" /> -->
  <!-- no layout pages -->
  <!-- <Route path="landing" component="{Landing}" />
  <Route path="profile" component="{Profile}" /> -->
  <Route path="events" component="{Events}" />
  <Route path="partners" component="{Partners}" />
  <Route path="contact" component="{Contact}" />
  <Route path="saas" component="{saas}" />
  <Route path="daas" component="{daas}" />
  <Route path="consult" component="{consult}" />
  <Route path="si" component="{si}" />
  <Route path="security" component="{security}" />
  <Route path="build" component="{build}" />
  <Route path="app" component="{builda}" />
  <Route path="/" component="{Index}" />
</Router>
